<template>
  <section id="order">
    <v-container fluid>
      <v-row class="flex-nowrap-pc">
        <v-col
          v-if="$route.name !== 'OrderPrintLabel' && $route.name !== 'PrintOrderSummary' && $route.name !== 'PrintOrderSummaryList'"
          cols="auto"
          class="order-status-list-desktop">
          <router-link
            v-for="(status, key) in statusList"
            :key="`status-link-${key}`"
            tag="button"
            :to="{
              name: 'OrderListByStatus',
              params: { orderStatus: key },
              query: routerQuery
            }"
            :class="`status-box elevation-1 status-box-${key}`"
            :disabled="isFetchingOrderList">
            <div
              class="status-label">
              {{ status.label }}
            </div>
            <div
              v-if="!isFetchingOrderCount"
              class="status-value">
              {{ status.count }}
            </div>
            <LoadingMeatBall
              v-else
              class="status-value" />
          </router-link>
        </v-col>
        <v-col
          cols="12"
          class="order-status-list-mobile"
          style="overflow: auto;">
          <div
            class="d-flex text-center"
            style="width: fit-content">
            <router-link
              v-for="(status, key) in statusList"
              :key="`status-link-${key}`"
              tag="button"
              :to="{
                name: 'OrderListByStatus',
                params: { orderStatus: key },
                query: routerQuery
              }"
              :class="`status-mb-box status-mb-box-${key}`"
              :disabled="isFetchingOrderList">
              <div>{{ status.label }} </div>
              <div
                v-if="!isFetchingOrderCount"
                class="status-value">
                {{ status.count }}
              </div>
              <LoadingMeatBall
                v-else
                style="left: 0px" />
            </router-link>
          </div>
        </v-col>
        <v-col style="overflow: auto;">
          <router-view />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LoadingMeatBall from '@/components/LoadingMeatBall.vue'

export default {
  components: {
    LoadingMeatBall
  },
  computed: {
    ...mapGetters({
      statusList: 'Order/statusList',
      isFetchingOrderList: 'Order/isFetchingOrderList',
      isFetchingOrderCount: 'Order/isFetchingOrderCount'
    }),
    routerQuery () {
      return this.$route.name === 'OrderListByStatus' ? this.$route.query : {}
    }
  },
  created () {
    this.resetOrderState()
  },
  beforeDestroy () {
    this.resetOrderState()
  },
  methods: {
    ...mapActions({
      resetOrderState: 'Order/resetOrderState'
    })
  }
}
</script>

<style lang="scss">
@import '@/assets/css/gw-variables.scss';
.order-status-list-desktop {
  overflow: auto;
  max-height: calc(100vh - 100px);
  padding-top: 0;
  padding-bottom: 0;
  .status-box {
    padding-top: 100%;
    position: relative;
    margin-bottom: 10px;
    width: 80px;
    display: block;
    color: #000000;
    border-radius: 5px;
    transition: all .25s;
    .status-label,
    .status-value {
      position: absolute;
      width: 100%;
      text-align: center;
      font-size: 12px;
    }
    .status-label {
      top: 25px;
    }
    .status-value {
      top: 45px;
    }
  }
}

.order-status-list-mobile {
  display: none;
}
@media screen and (max-width: 600px) {
  .order-status-list-desktop {
    display: none !important;
  }

  .order-status-list-mobile {
    display: block !important;
  }

  .flex-nowrap-pc {
    flex-wrap: wrap !important;
  }
}

.flex-nowrap-pc {
  flex-wrap: nowrap;
}

.status-box:hover,
.status-box.router-link-active {
  color: #ffffff;
}
.status-box {
  border-left: 2px solid $bill-status;
}
.status-box:hover,
.status-box.router-link-active {
  background-color: $bill-status;
}
.status-box.status-box-pending {
  border-left: 2px solid $bill-status-pending;
}
.status-box.status-box-pending:hover,
.status-box.status-box-pending.router-link-active {
  background-color: $bill-status-pending;
}
.status-box.status-box-reserved {
  border-left: 2px solid $bill-status-reserved;
}
.status-box.status-box-reserved:hover,
.status-box.status-box-reserved.router-link-active {
  background-color: $bill-status-reserved;
}
.status-box.status-box-update_shipping {
  border-left: 2px solid $bill-status-update_shipping;
}
.status-box.status-box-update_shipping:hover,
.status-box.status-box-update_shipping.router-link-active {
  background-color: $bill-status-update_shipping;
}
.status-box.status-box-paid {
  border-left: 2px solid $bill-status-paid;
}
.status-box.status-box-paid:hover,
.status-box.status-box-paid.router-link-active {
  background-color: $bill-status-paid;
}
.status-box.status-box-waiting {
  border-left: 2px solid $bill-status-waiting;
}
.status-box.status-box-waiting:hover,
.status-box.status-box-waiting.router-link-active {
  background-color: $bill-status-waiting;
}
.status-box.status-box-ready_to_ship {
  border-left: 2px solid $bill-status-readytoship;
}
.status-box.status-box-ready_to_ship:hover,
.status-box.status-box-ready_to_ship.router-link-active {
  background-color: $bill-status-readytoship;
}
.status-box.status-box-completed {
  border-left: 2px solid $bill-status-completed;
}
.status-box.status-box-completed:hover,
.status-box.status-box-completed.router-link-active {
  background-color: $bill-status-completed;
}
.status-box.status-box-void {
  border-left: 2px solid $bill-status-void;
}
.status-box.status-box-void:hover,
.status-box.status-box-void.router-link-active {
  background-color: $bill-status-void;
}

.status-box.status-box-exchange_return {
  border-left: 2px solid $bill-status-exchange_return;
}
.status-box.status-box-exchange_return:hover,
.status-box.status-box-exchange_return.router-link-active {
  background-color: $bill-status-exchange_return;
}

.status-box.status-box-expired {
  border-left: 2px solid $bill-status-expired;
}
.status-box.status-box-expired:hover,
.status-box.status-box-expired.router-link-active {
  background-color: $bill-status-expired;
}

.status-mb-box:hover,
.status-mb-box.router-link-active {
  color: #ffffff;
}
.status-mb-box {
  font-size: 12px;
  // display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  border-radius: 8px;
  width: 80px !important;
  height: 80px !important;
  border: 2px solid $bill-status;
}
.status-mb-box:hover,
.status-mb-box.router-link-active {
  background-color: $bill-status;
}
.status-mb-box.status-mb-box-pending {
  border: 2px solid $bill-status-pending;
}
.status-mb-box.status-mb-box-pending:hover,
.status-mb-box.status-mb-box-pending.router-link-active {
  background-color: $bill-status-pending;
}
.status-mb-box.status-mb-box-reserved {
  border: 2px solid $bill-status-reserved;
}
.status-mb-box.status-mb-box-reserved:hover,
.status-mb-box.status-mb-box-reserved.router-link-active {
  background-color: $bill-status-reserved;
}
.status-mb-box.status-mb-box-paid {
  border: 2px solid $bill-status-paid;
}
.status-mb-box.status-mb-box-paid:hover,
.status-mb-box.status-mb-box-paid.router-link-active {
  background-color: $bill-status-paid;
}
.status-mb-box.status-mb-box-waiting {
  border: 2px solid $bill-status-waiting;
}
.status-mb-box.status-mb-box-waiting:hover,
.status-mb-box.status-mb-box-waiting.router-link-active {
  background-color: $bill-status-waiting;
}
.status-mb-box.status-mb-box-ready_to_ship {
  border: 2px solid $bill-status-readytoship;
}
.status-mb-box.status-mb-box-ready_to_ship:hover,
.status-mb-box.status-mb-box-ready_to_ship.router-link-active {
  background-color: $bill-status-readytoship;
}
.status-mb-box.status-mb-box-completed {
  border: 2px solid $bill-status-completed;
}
.status-mb-box.status-mb-box-completed:hover,
.status-mb-box.status-mb-box-completed.router-link-active {
  background-color: $bill-status-completed;
}
.status-mb-box.status-mb-box-expired {
  border: 2px solid $bill-status-expired;
}
.status-mb-box.status-mb-box-expired:hover,
.status-mb-box.status-mb-box-expired.router-link-active {
  background-color: $bill-status-expired;
}
</style>
